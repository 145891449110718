<template>
  <el-dialog
    title="Загрузка иконок дорожных знаков"
    :visible.sync="isShow"
    width="400px"
  >
    <div class="book-create-icons">
      <el-upload
        ref="icon_upload"
        multiple
        drag
        :on-success="onSuccessUpload"
        :action="actionUrl"
        :headers="headers"
        :show-file-list="false"
        class="r-upload"
      >
        <div class="el-upload__wrapper">
          <r-icon
            :size="30"
            name="upload"
          />
          <r-text color-type="accent-primary">
            {{ $t('book-create-icons:' + 'files:to-attach') }}
          </r-text>
          <r-text type="caption">
            {{ $t('book-create-icons:' + 'files:upload-desc') }}
          </r-text>
        </div>
      </el-upload>
    </div>
  </el-dialog>
</template>

<script>
import constants from '@/constants/url'
import { getHeaders, notifyFactory } from '@/utils'

export default {
  computed: {
    iconsSourceId() {
      return this.$store.state.book.iconsSourceId
    },
    actionUrl() {
      return `${constants.URL}/upload_resource?source_id=${this.iconsSourceId}`
    },
    headers() {
      return getHeaders()
    },
    isShow: {
      get() {
        return this.$store.state.book.showIconsModal
      },
      set(value) {
        this.$store.commit('BOOK_SET_FIELD', {
          field: 'showIconsModal',
          value
        })
      }
    }
  },
  methods: {
    onSuccessUpload(res, file) {
      this.createObject(res.resources_ids[0], file?.name.split('.').slice(0, -1).join('.'))
    },
    async createObject(resource_id, name) {
      if (!resource_id) return
      try {
        await this.$store.dispatch('POST_REQUEST', {
          url: `objectInfo/${this.iconsSourceId}`,
          data: {
            name,
            resource_id
          }
        })
        const title = this.$t('book-create-icons:' + 'create-object:notify-success')
        const message = this.$t('book-create-icons:' + 'create-object:notify-success-text')
        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('UPDATE_ACTIVE_TABLE')
        this.isShow = false
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
